<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-if="!loading">
      <h5 class="text-center text-primary">View Children's Evaluation</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Select a child</strong>
            <b-list-group>
              <b-list-group-item v-for="item in listAllStudents" :key="item.given_name" href=# button @click="onClickStudent(item)" :active="user_id == item.user_id">
                <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>
                {{generateNameLabel(item.given_name, item.family_name, item.nickname, item.email)}}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">Step 2: View details</strong>
            <div class="text-center" v-if="loadDetails">
              <b-spinner label="Loading..." variant="warning"></b-spinner>
            </div>
            <b-table
              v-else
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
            ></b-table>

            <div>
              Sorting By: <b>{{ sortBy }}</b>, Sort Direction:
              <b>{{ sortDesc ? 'descending' : 'ascending' }}</b>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </span>
    <div class="mt-3" />
  </div>
</template>

<script>
  import EvaluationService from '@/services/EvaluationService.js';

  export default {
    name: 'studentevaluationparentsview',
    title: "TPA Gembira - View children evaluation",
    data() {
      return {
        loading: false,
        loadDetails: false,
        listAllStudents: [],
        user_id: null,
        sortBy: 'date',
        sortDesc: false,
        fields: [
          { key: 'date', sortable: true },
          { key: 'content', sortable: true },
          { key: 'notes', sortable: true },
          { key: 'teacher', sortable: true }
        ],
        items: []
      }
    },
    created(){
      this.init();
    },
    methods: {
      onClickStudent(item){
        if (this.user_id !== item.user_id){
          this.loadDetails = true;
          this.user_id = item.user_id; 
          this.items = [];
          EvaluationService.getEvaluation(this.user_id, null, null, null)
            .then(evaluation => {
              evaluation.forEach((item) => {
                this.items.push({ 
                  'date' : item.date, 
                  'content': item.content, 
                  'notes': item.remarks, 
                  'teacher' : this.generateNameLabel(item.teacher.given_name, item.teacher.family_name, item.teacher.nickname, item.teacher.email)                
                });
              });
              this.loadDetails = false;
            });
        }
      },
      async init(){
        this.loading = true;
        this.listAllStudents = this.$auth.user['https://tpa.musihoven.nl/parentsof'];
        this.loading = false;
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>